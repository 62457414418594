import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../../common/CommonLoader";
import FilledButton from "../../common/FilledButton";
import {
  ACCEPT_ORDER,
  DELAY_ORDER,
  GET_ORDER_DETAILS,
  ORDER_PICKED_UP,
  ORDER_READY,
  REFUND_FULL_ORDER,
  REFUND_PARTIAL_ORDER,
  REJECT_ORDER,
} from "../../constants/ApiEndpoints";
import APIController from "../../data/APIController";
import { showAPIError } from "../../helpers/FieldHelpers";
import { showToastMessage } from "../../helpers/ToastHelpers";
import PlaySound from "../../sound/PlaySound";

import { FaChevronLeft } from "react-icons/fa6";
import { toSentenceCase } from "../../helpers/StringHelpers";
import AwaitingButtonGroup from "../../common/buttons/AwaitingButtonGroup";
import PreparingButtonGroup from "../../common/buttons/PreparingButtonGroup";
import { formatOrderDate } from "../../helpers/DateHelpers";
import OrderActionModal from "../../common/modals/OrderActionModal";
import ReadyButtonGroup from "../../common/buttons/ReadyButtonGroup";
import DelayOrderModal from "../../common/modals/DelayOrderModal";
import RefundAmountModal from "../../common/modals/RefundAmountModal";
import CancelOrderModal from "../../common/modals/CancelOrderModal";
import { closePopup, openPopup } from "../../helpers/ModalHelpers";

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);

  // Order Progress States
  const [acceptingOrder, setAcceptingOrder] = useState(false);
  const [decliningOrder, setDecliningOrder] = useState(false);
  const [readyingOrder, setReadyingOrder] = useState(false);
  const [delayingOrder, setDelayingOrder] = useState(false);
  const [refundingOrder, setRefundingOrder] = useState(false);

  // Order Actions
  const [showActions, setShowActions] = useState(false);
  const [showDelay, setShowDelay] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

  const [shouldPlay, setShouldPlay] = useState(false);

  // State Loading
  const [printing, setPrinting] = useState(false);

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {
    console.log("ORDER DETAILS: ", orderDetails);
    if (orderDetails) {
      if (orderDetails.status === "awaiting") {
        setShouldPlay(true);
      }
    }
  }, [orderDetails]);

  const getOrderDetails = () => {
    console.log("Order ID: ", id);
    setLoading(true);
    APIController.get(`${GET_ORDER_DETAILS}${id}`)
      .then((response) => {
        console.log("Order Details Response: ", response);

        if (response.data.success == false) {
          navigate(-1);
          showAPIError(response.data?.message ?? "");
          return;
        }

        setOrderDetails(response.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error Fetching Order Details: ", error);
      });
  };

  const acceptOrder = () => {
    setAcceptingOrder(true);
    APIController.patch(ACCEPT_ORDER, {
      orderId: orderDetails?.id ?? "",
    })
      .then((response) => {
        console.log("ACCEPT ORDER RESPONSE: ", response);
        setShouldPlay(false);
        getOrderDetails();
        setAcceptingOrder(false);
        setTimeout(() => {
          printOrder();
        }, 500);
      })
      .catch((error) => {
        console.log("Error Accepting Order: ", error);
        setAcceptingOrder(false);
      });
  };

  const rejectOrder = () => {
    setDecliningOrder(true);
    APIController.patch(REJECT_ORDER, {
      orderId: orderDetails?.id ?? "",
    })
      .then((response) => {
        console.log("REJECT ORDER RESPONSE: ", response);
        setShouldPlay(false);
        window.history.back();
      })
      .catch((error) => {
        console.log("Error Accepting Order: ", error);
        setDecliningOrder(false);
      });
  };

  const markOrderReady = () => {
    setReadyingOrder(true);
    APIController.patch(ORDER_READY, {
      orderId: orderDetails?.id ?? "",
    })
      .then((response) => {
        console.log("ORDER READY RESPONSE: ", response);
        setReadyingOrder(false);
        window.history.back();
      })
      .catch((error) => {
        console.log("Error Accepting Order: ", error);
        setReadyingOrder(false);
      });
  };

  const markOrderPickedUp = () => {
    APIController.patch(ORDER_PICKED_UP, {
      orderId: orderDetails?.id ?? "",
    })
      .then((response) => {
        console.log("ORDER PICKED UP RESPONSE: ", response);
        window.history.back();
      })
      .catch((error) => {
        console.log("Error Accepting Order: ", error);
      });
  };

  const refundPartialOrder = (amount) => {
    if (!amount || amount == 0.0) {
      return alert("Please enter an amount greater than $0.00");
    }

    setRefundingOrder(true);
    APIController.patch(REFUND_PARTIAL_ORDER, {
      orderId: orderDetails?.id ?? "",
      amount: amount,
    })
      .then((response) => {
        console.log("REFUND FULL ORDER RESPONSE: ", response);
        getOrderDetails();

        setRefundingOrder(false);
        setShowRefund(false);

        if (response.data.success == false) {
          return openPopup({
            title: "Error Refunding Amount",
            description: response?.data.message ?? "",
            positiveButtonTap: () => closePopup(),
            positiveButtonText: "Close",
          });
        }
      })
      .catch((error) => {
        console.log("Error Refunding Full Order: ", error);
      });
  };

  const refundFullOrder = () => {
    setShowCancel(false);
    APIController.patch(REFUND_FULL_ORDER, {
      orderId: orderDetails?.id ?? "",
    })
      .then((response) => {
        console.log("REFUND FULL ORDER RESPONSE: ", response);
        if (response.data.success == false) {
          showAPIError(response?.data?.message ?? "");
          return;
        }
        getOrderDetails();
      })
      .catch((error) => {
        console.log("Error Refunding Full Order: ", error);
      });
  };

  const delayOrder = (minutes) => {
    setDelayingOrder(true);
    APIController.patch(DELAY_ORDER, {
      orderId: orderDetails?.id ?? "",
      minutes: minutes,
    })
      .then((response) => {
        console.log("ORDER DELAYED RESPONSE: ", response);
        setShowDelay(false);
        setDelayingOrder(false);
        getOrderDetails();
      })
      .catch((error) => {
        console.log("Error Delaying Order: ", error);
        setDelayingOrder(false);
      });
  };

  const printOrder = () => {
    setPrinting(true);
    showToastMessage("Printing Order", "Your order will print momentarily");
    const message = JSON.stringify({
      type: "printOrder",
      orderId: id,
    });
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    }

    setTimeout(() => {
      setPrinting(false);
    }, 3000);
  };

  return (
    <>
      <PlaySound shouldPlay={shouldPlay} />
      <OrderActionModal
        isVisible={showActions}
        onClose={() => setShowActions(false)}
        onDelay={() => {
          setShowActions(false);
          setShowDelay(true);
        }}
        onRefund={() => {
          setShowActions(false);
          setShowRefund(true);
        }}
        onCancel={() => {
          setShowActions(false);
          setShowCancel(true);
        }}
      />

      <DelayOrderModal
        isVisible={showDelay}
        isDelaying={delayingOrder}
        onClose={() => setShowDelay(false)}
        onSubmit={delayOrder}
      />

      <RefundAmountModal
        isVisible={showRefund}
        onClose={() => setShowRefund(false)}
        refunding={refundingOrder}
        onRefund={(value) => {
          console.log("REFUND VALUE: ", value);
          refundPartialOrder(value);
        }}
      />

      <CancelOrderModal
        isVisible={showCancel}
        onConfirm={() => refundFullOrder()}
        onClose={() => setShowCancel(false)}
      />

      {loading ? (
        <>
          <div
            className="pt-12"
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CommonLoader width={128} height={128} />
            <p className="font-medium mt-2">Updating Order Details</p>
          </div>
        </>
      ) : (
        <>
          {/* Order Details Header */}
          <div className="flex flex-row fixed top-0 pt-6 pb-4 px-6 bg-white w-screen">
            <FaChevronLeft
              size={20}
              onClick={() => {
                navigate("/restaurant");
              }}
            />

            <div style={{ flex: 1 }} />
            <p className="text-base font-medium">
              Order {orderDetails?.bookingId?.toUpperCase() ?? ""}
            </p>
            <div style={{ flex: 1 }} />

            <FaChevronLeft size={20} color={"#ffffff"} />
          </div>

          <div className="px-6 mt-20 pb-8">
            {/* Customer Details */}
            <div className="border border-slate-200 rounded-lg px-2 py-2 mt-4">
              {/* Order ID */}
              <div className="flex flex-row">
                <p className="text-lg font-semibold">Order ID</p>
                <div style={{ flex: 1 }} />
                <p className="text-lg font-medium">
                  {orderDetails?.bookingId?.toUpperCase() ?? ""}
                </p>
              </div>

              {/* Order Type */}
              <div className="flex flex-row mt-3">
                <p className="text-lg font-semibold">Order Type</p>
                <div style={{ flex: 1 }} />
                <p className="text-lg font-medium">
                  {toSentenceCase(orderDetails?.orderType ?? "pickup")}
                </p>
              </div>

              {/* Order Time */}
              <div className="flex flex-row mt-3">
                <p className="text-lg font-semibold">Order Time</p>
                <div style={{ flex: 1 }} />
                <p className="text-lg font-medium">
                  {orderDetails?.orderTime
                    ? formatOrderDate(
                        orderDetails?.orderTime ?? new Date().toISOString()
                      )
                    : "ASAP"}
                </p>
              </div>

              {/* Requested For */}
              <div className="flex flex-row mt-3">
                <p
                  className="text-lg font-semibold"
                  style={{
                    color: orderDetails?.requestedTime ? "#FF7900" : "black",
                  }}
                >
                  Requested for
                </p>
                <div style={{ flex: 1 }} />
                <p
                  className="text-lg font-medium"
                  style={{
                    color: orderDetails?.requestedTime ? "#FF7900" : "black",
                  }}
                >
                  {orderDetails?.requestedTime
                    ? formatOrderDate(
                        orderDetails?.requestedTime ?? new Date().toISOString()
                      )
                    : "ASAP"}
                </p>
              </div>
            </div>

            {/* Order Actions */}
            <div className="mt-3">
              {/* Awaiting Actions */}
              {orderDetails.status == "awaiting" ? (
                <AwaitingButtonGroup
                  orderId={orderDetails?.id ?? ""}
                  onAccept={acceptOrder}
                  onReject={rejectOrder}
                  acceptingOrder={acceptingOrder}
                  rejectingOrder={decliningOrder}
                />
              ) : orderDetails.status == "preparing" ? (
                <PreparingButtonGroup
                  onPrint={printOrder}
                  onReady={markOrderReady}
                  onActions={() => setShowActions(true)}
                  isReadying={readyingOrder}
                />
              ) : orderDetails.status == "ready" ? (
                <ReadyButtonGroup
                  orderType={orderDetails?.orderType ?? "pickup"}
                  onPickedUp={markOrderPickedUp}
                  onPrint={printOrder}
                  onActions={() => setShowActions(true)}
                />
              ) : (
                <></>
              )}
            </div>

            {/* Order Details */}
            <p className="text-lg font-medium mt-4">Ordered Items</p>
            <div className="mt-2">
              {orderDetails?.items && orderDetails?.items?.length > 0
                ? orderDetails?.items?.map((item) => {
                    return (
                      <div
                        key={item?.id ?? ""}
                        className="border border-slate-200 rounded-xl flex flex-row justify-start items-center mb-2"
                      >
                        <img
                          src={item?.item?.image ?? ""}
                          className="w-20 h-20 object-cover rounded-lg"
                        />

                        <div className="flex flex-col ml-4">
                          <p className="text-lg font-medium">
                            {item?.quantity ?? 0} x {item?.item?.name ?? ""}
                          </p>
                          <p className="text-lg font-normal">
                            ${Number(item?.price).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>

            <p className="text-lg font-medium mt-4">Customer</p>

            {/* Customer Details */}
            <div className="border border-slate-200 rounded-lg px-2 py-2 mt-2">
              {/* Customer Name */}
              <div className="flex flex-row">
                <p className="text-lg font-semibold">Name</p>
                <div style={{ flex: 1 }} />
                <p className="text-lg font-medium">
                  {orderDetails?.customer?.firstName ?? ""}{" "}
                  {orderDetails?.customer?.lastName ?? ""}
                </p>
              </div>

              {/* Customer Phone Number */}
              <div className="flex flex-row mt-3">
                <p className="text-lg font-semibold">Phone Number</p>
                <div style={{ flex: 1 }} />
                <p className="text-lg font-medium">
                  {toSentenceCase(orderDetails?.customer?.mobile ?? "")}
                </p>
              </div>

              {/* Customer Previous Orders */}
              <div className="flex flex-row mt-3">
                <p className="text-lg font-semibold">Previous Orders</p>
                <div style={{ flex: 1 }} />
                <p className="text-lg font-medium">
                  {orderDetails?.previousOrders ?? 0}
                </p>
              </div>
            </div>

            <p className="text-lg font-medium mt-4">Totals</p>

            {/* Order Totals */}
            <div className="border border-slate-200 rounded-lg px-2 py-2 mt-2">
              {/* Order Subtotal */}
              <div className="flex flex-row">
                <p className="text-lg font-semibold">Subtotal</p>
                <div style={{ flex: 1 }} />
                <p className="text-lg font-medium">
                  ${Number(orderDetails?.subtotal ?? 0.0).toFixed(2)}
                </p>
              </div>

              {/* Order Transaction Fee */}
              <div className="flex flex-row mt-3">
                <p className="text-lg font-semibold">Transaction Fee</p>
                <div style={{ flex: 1 }} />
                <p className="text-lg font-medium">
                  ${Number(orderDetails?.transactionFee ?? 0.0).toFixed(2)}
                </p>
              </div>

              {/* Delivery Fee */}
              {orderDetails?.deliveryFee != "0" ? (
                <div className="flex flex-row mt-3">
                  <p className="text-lg font-semibold">Delivery Fee</p>
                  <div style={{ flex: 1 }} />
                  <p className="text-lg font-medium">
                    ${Number(orderDetails?.deliveryFee ?? 0.0).toFixed(2)}
                  </p>
                </div>
              ) : null}

              {/* Partial Refund */}
              {orderDetails?.refundedAmount != "0" ? (
                <div className="flex flex-row mt-3">
                  <p className="text-lg font-semibold">Refunded Amount</p>
                  <div style={{ flex: 1 }} />
                  <p className="text-lg font-medium">
                    ${Number(orderDetails?.refundedAmount ?? 0.0).toFixed(2)}
                  </p>
                </div>
              ) : null}

              {/* Discount */}
              {orderDetails?.discountCode ? (
                <div className="flex flex-row mt-3">
                  <p className="text-lg font-semibold text-[#FF4A49]">
                    Discount
                  </p>
                  <div style={{ flex: 1 }} />
                  <p className="text-lg font-medium text-[#FF4A49]">
                    - ${Number(orderDetails?.discount ?? 0.0).toFixed(2)}
                  </p>
                </div>
              ) : null}

              {/* Service Fee */}
              {orderDetails?.serviceFee != "0" ? (
                <div className="flex flex-row mt-3">
                  <p className="text-lg font-semibold text-[#FF4A49]">
                    Service Fee
                  </p>
                  <div style={{ flex: 1 }} />
                  <p className="text-lg font-medium text-[#FF4A49]">
                    - ${Number(orderDetails?.serviceFee ?? 0.0).toFixed(2)}
                  </p>
                </div>
              ) : null}

              {/* Total */}
              <div className="flex flex-row mt-3">
                <p className="text-lg font-semibold">Total</p>
                <div style={{ flex: 1 }} />
                <p className="text-lg font-medium">
                  ${Number(orderDetails?.total ?? 0.0).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OrderDetails;
